<template>
  <div class="list-wrapper">
    <a-calendar v-model:value="date" @panelChange="initList">
      <template #dateCellRender="{ current }">
        <a-space direction="vertical" style="width: 100%">
          <div
            v-for="item in getList(current)"
            :key="item.id"
            :class="item.class"
            @click="handleClick(item)"
          >
            {{ item.campaignName }}
          </div>
        </a-space>
      </template>
    </a-calendar>
    <a-drawer :width="400" :closable="false" v-model:visible="visible">
      <a-space direction="vertical" size="middle">
        <div class="task-second">{{ task.currentDate?.substr(0, 4) }}</div>
        <div class="task-title">
          <span>{{ task.currentDate?.substr(5) }}</span>
          <a-button
            type="primary"
            ghost
            @click="$has(`${type}:detail`).then(() => goTo(task))"
          >
            详情
          </a-button>
        </div>
        <div class="task-banner"><img :src="task.banner" /></div>
        <div>
          <a-tag :color="task.type?.[0]"> {{ task.type?.[1] }} </a-tag>
          <span class="task-name">{{ task.campaignName }}</span>
        </div>
        <div class="task-second">
          <div>
            <BankTwoTone class="mr-5" />
            {{ task.country }}
            {{ task.province ? ` • ${task.province}` : '' }}
            {{ task.city ? ` • ${task.city}` : '' }}
          </div>
          <div>
            <EnvironmentTwoTone class="mr-5" />
            {{ task.location }}
          </div>
          <div>
            <CalendarTwoTone class="mr-5" />
            {{ dateFormat(task.startTime) }} ~
            {{ dateFormat(task.endTime) }}
          </div>
        </div>
        <div class="task-statistic">
          <div class="statistic-item">
            <div class="item-count">{{ task.attendeeCount }}</div>
            <div class="item-title">报名人数</div>
          </div>
          <div class="statistic-item" v-if="task.loginCount">
            <div class="item-count">{{ task.loginCount }}</div>
            <div class="item-title">登录数</div>
          </div>
          <div class="statistic-item" v-else>
            <div class="item-count">{{ task.checkinCount }}</div>
            <div class="item-title">签到数</div>
          </div>
        </div>
      </a-space>
    </a-drawer>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';
import { Calendar, Drawer, Space, Tag } from 'ant-design-vue';
import {
  EnvironmentTwoTone,
  CalendarTwoTone,
  BankTwoTone,
} from '@ant-design/icons-vue';
import { dateFormat } from '@/utils';
import activityAPI from '@/api/activity.js';

export default {
  components: {
    ACalendar: Calendar,
    ASpace: Space,
    ADrawer: Drawer,
    ATag: Tag,
    EnvironmentTwoTone,
    CalendarTwoTone,
    BankTwoTone,
  },
  props: {
    type: String,
    search: Object,
  },
  setup(props) {
    const typeList = {
      //all: '101', // 全部任务默认线下主办会
      host: '101', // 线下主办会
      exhibition: '102', // 线下参展
      live: '103', // 线上会议
      // 104 Social传播
      thematic: '105', // Website阵地
      socialmedia: '106', // Social阵地
      showroom: '107', // 线上展厅
      common: '108', // 通用任务
    };
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      dataSource: [],
      visible: false,
      task: {},
      date: moment(),
    });
    const initList = date => {
      date = date.clone();
      activityAPI
        .getList(route.path, {
          tempId: typeList[props.type],
          current: 1,
          size: 100,
          ...props.search,
          startDate: `${dateFormat(date.startOf('month'))} 00:00:00`,
          endDate: `${dateFormat(date.endOf('month'))} 23:59:59`,
        })
        .then(({ records }) => {
          state.dataSource = records;
        });
    };
    const goTo = ({ id, eventId, campaignId }) =>
      router.push(`${route.path}/${id}/${eventId}/${campaignId}`);

    watch([props.search, () => props.type], () => initList(state.date));
    initList(state.date);

    return {
      ...toRefs(state),
      handleClick: item => {
        state.visible = true;
        state.task = item;
      },
      goTo,
      dateFormat,
      initList,
      getList(value) {
        const list = [];
        const now = new Date().getTime();
        const startTime = new Date(`${dateFormat(value)} 00:00:00`).getTime();
        const endTime = new Date(`${dateFormat(value)} 23:59:59`).getTime();
        state.dataSource.forEach(item => {
          let temp = { ...item };
          const start = new Date(item.startTime).getTime();
          const end = new Date(item.endTime).getTime();
          if (start <= endTime && end >= startTime) {
            if (now < start) {
              temp.class = 'list-item item-0';
              temp.type = ['processing', '未开始'];
            } else if (now > end) {
              temp.class = 'list-item item-1';
              temp.type = ['default', '已结束'];
            } else {
              temp.class = 'list-item';
              temp.type = ['success', '进行中'];
            }
            list.push({
              ...temp,
              currentDate: value.format(
                store.state.account.language === 'zh-CN'
                  ? 'YYYY MMMDo，dddd'
                  : 'YYYY MMMM Do, dddd'
              ),
            });
          }
        });
        return list;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.list-wrapper {
  padding: 4px 8px 12px 8px;
  max-height: calc(100vh - 139px);
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 3px 10px 0px @primary-3;
  border-radius: 8px;
  :deep(.ant-fullcalendar-header .ant-radio-group) {
    display: none;
  }
  .list-item {
    padding-left: 5px;
    line-height: 24px;
    background-color: @green-1;
    border-left: 5px solid @green-5;
    border-radius: 0 0 10px 0;
    &:hover {
      box-shadow: 2px 2px 4px #aaa;
    }
    &.item-0 {
      background-color: @blue-1;
      border-color: @blue-5;
    }
    &.item-1 {
      background-color: #f0f0f0;
      border-left: 5px solid #aaa;
    }
  }
}
.task-second {
  color: @text-color-secondary;
}
.task-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 24px;
  }
}
.task-banner img {
  width: 100%;
  border-radius: 8px;
}
.task-name {
  font-size: 18px;
  vertical-align: middle;
}
.task-statistic {
  background-color: #f6f7fb;
  border-radius: 10px;
  padding: 15px 35px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
}
.statistic-item {
  .item-title {
    margin-top: 5px;
    color: @text-color-secondary;
  }
  .item-count {
    font-size: 32px;
    color: @primary-color;
  }
}
</style>
