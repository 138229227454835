<template>
  <div class="list-wrapper">
    <a-row type="flex" align="middle" class="list-title">
      <a-col :flex="1">
        <a-checkbox
          v-model:checked="checkAll"
          :indeterminate="indeterminate"
          @change="handleChangeByCheckAll"
        >
          全选
        </a-checkbox>
        <a-dropdown>
          <span class="move">
            移动到
            <down-outlined />
          </span>
          <template #overlay>
            <a-menu @click="handleClickByMenu">
              <a-menu-item v-for="item in groupList" :key="item.id">
                {{ item.campaignGroupName }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-col>
      <a-range-picker allow-clear @change="handleChangeByDate">
        <template #suffixIcon><calendar-outlined /></template>
      </a-range-picker>
    </a-row>
    <a-checkbox-group
      v-model:value="selectedKeys"
      @change="handleChange"
      style="width: 100%"
    >
      <!--    //min-height: calc(100vh - 273px);
  min-height: calc(100vh - 340px);
    :style="{padding: language === 'en' ? '0px 13px': '0px 31px'}"
    -->
      <a-list
        item-layout="horizontal"
        :loading="loading"
        :data-source="dataSource"
        :pagination="pagination"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <div class="ml-20 mr-13">
              <a-checkbox :value="item.id" />
            </div>
            <a-list-item-meta>
              <template #avatar>
                <img
                  class="banner"
                  :src="item.banner"
                  @click="handleClick(item)"
                />
              </template>
              <template #title>
                <div>
                  <span class="title" @click="handleClick(item)">
                    {{ item.campaignName }}
                  </span>
                  <span class="ml-10">
                    <a-tag v-if="getStatus(item) === 0" color="warning">
                      未开始
                    </a-tag>
                    <a-tag v-else-if="getStatus(item) === 1" color="error">
                      已结束
                    </a-tag>
                    <a-tag v-else color="success">进行中</a-tag>
                  </span>
                </div>
              </template>
              <template #description>
                <div class="address">
                  <span>
                    <img src="@/assets/images/activity/list1.png" alt="" />
                    <span>
                      {{ item.country }}
                    </span>
                    <span>
                      {{ item.province ? ` • ${item.province}` : '' }}
                    </span>
                    <span>{{ item.city ? ` • ${item.city}` : '' }}</span>
                  </span>
                  <span class="userName">
                    <img src="@/assets/images/activity/list2.png" alt="" />
                    <span>{{ item.createUserName }}</span>
                  </span>
                </div>
                <div class="list-time">
                  <span>
                    <img src="@/assets/images/activity/list3.png" alt="" />
                  </span>
                  <span>
                    {{ dateFormat(item.startTime) }} ~
                    {{ dateFormat(item.endTime) }}
                  </span>
                </div>
              </template>
            </a-list-item-meta>
            <template #actions>
              <operation
                :options="options"
                :record="item"
                class="mr-12"
                size="small"
              />
            </template>
            <a-space size="large">
              <div class="statistic-item">
                <div class="item-count">{{ item.attendeeCount }}</div>
                <div class="item-title">报名人数</div>
              </div>
              <div class="statistic-item">
                <div class="item-count">{{ item.checkinCount }}</div>
                <div class="item-title">签到人数</div>
              </div>
            </a-space>
          </a-list-item>
        </template>
      </a-list>
    </a-checkbox-group>
  </div>
</template>

<script>
import { computed, getCurrentInstance, reactive, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  List,
  Menu,
  Row,
  Space,
  Tag,
} from 'ant-design-vue';
import { DownOutlined, CalendarOutlined } from '@ant-design/icons-vue';
import Operation from '@/components/operation';
import { dateFormat } from '@/utils';
import activityApi from '@/api/activity.js';
import { local } from '@/utils/storage';

export default {
  components: {
    AList: List,
    AListItem: List.Item,
    AListItemMeta: List.Item.Meta,
    ATag: Tag,
    ASpace: Space,
    ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ARangePicker: DatePicker.RangePicker,
    ARow: Row,
    ACol: Col,
    DownOutlined,
    CalendarOutlined,
    Operation,
  },
  props: {
    type: String,
    search: Object,
    groupList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const typeList = {
      // all: '101', // 全部任务默认线下主办会
      host: '101', // 线下主办会
      exhibition: '102', // 线下参展
      live: '103', // 线上会议
      // 104 Social传播
      thematic: '105', // Website阵地
      socialmedia: '106', // Social阵地
      showroom: '107', // 线上展厅
      common: '108', // 通用任务
    };
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      loading: false,
      dataSource: [],
      searchDate: {},
      checkAll: false,
      indeterminate: false,
      selectedKeys: [],
      current: 1,
      size: 10,
      total: 0,
    });

    const computedState = () => {
      if (state.dataSource.length > 0) {
        return {
          current: state.current,
          pageSize: state.size,
          total: state.total,
          showLessItems: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `共 ${total} 条，当前显示 ${range[0]}-${range[1]} 条`,
          onChange: (current, pageSize) => initList(current, pageSize),
          onShowSizeChange: (current, pageSize) => initList(current, pageSize),
        };
      }
    };

    const pagination = computed(() => {
      return computedState();
    });

    const initList = (current, size) => {
      state.loading = true;
      state.current = current;
      state.size = size;
      activityApi
        .getList(route.path, {
          tempId: typeList[props.type],
          current,
          size,
          ...props.search,
          ...state.searchDate,
        })
        .then(({ records, total }) => {
          state.dataSource = records;
          state.total = parseInt(total);
          state.checkAll = false;
          state.indeterminate = false;
          state.selectedKeys = [];
        })
        .finally(() => {
          state.loading = false;
        });
    };
    const goTo = ({ id, eventId, campaignId }) => {
      // 存储  campaignId
      local.set('CAMPAIGNID', campaignId);
      router.push(`${route.path}/${id}/${eventId}/${campaignId}`);
    };

    watch([props.search, () => props.type], () => initList(1, state.size));
    initList(state.current, state.size);

    const setId = record => {
      activityApi
        .getCampaignById('', { campaignId: record.campaignId })
        .then(res => {
          store.commit('common/setCampaignOrgId', res.orgId);
          store.commit('common/setCreateUser', res.createUser);

          activityApi
            .delete(`${props.type}:delete`, { ids: [record.id] })
            .then(() => {
              proxy.$message.success('操作成功');
              initList(1, state.size);
            });
        });
    };

    const { userId } = store.state.account.user;
    activityApi.authorityOrgByUserId('', { userId: userId }).then(res => {
      store.commit('common/setOrgIds', res?.orgIds);
      store.commit('common/setUserId', res.userId);
    });
    return {
      options: [
        {
          type: 'detail',
          label: '详情',
          icon: 'EyeTwoTone',
          permission: () => `${props.type}:detail`,
          fnClick: goTo,
        },
        // 暂时隐藏列表视图的编辑按钮
        // {
        //   type: 'copy',
        //   label: '复制',
        //   icon: 'CopyTwoTone',
        //   permission: () => `${props.type}:copy`,
        //   fnClick: ({ id }) => {
        //     activityApi.getItemById(`${props.type}:copy`, id).then(data => {
        //       data.campaignName += ' copy';
        //       data.tempApp = Object.values(data.tempApp)
        //         .map(item => item.map(i => ({ ...i, isConfig: 'true' })))
        //         .flat();
        //       delete data.id;
        //       activityApi.add(`${props.type}:copy`, data).then(() => {
        //         proxy.$message.success('操作成功');
        //         initList(1, state.size);
        //       });
        //     });
        //   },
        // },
        {
          type: 'delete',
          permission: () => `${props.type}:delete`,
          show: record => {
            let orgIds = store.state.common.orgIds?.includes(record.orgId);
            if (orgIds || store.state.common.userId === record.createUser) {
              return true;
            } else {
              return false;
            }
          },
          // disabled: record => {
          //   let orgIds = store.state.common.orgIds?.includes(record.orgId);
          //   if (orgIds || store.state.common.userId === record.createUser) {
          //     return true;
          //   } else {
          //     return false;
          //   }
          // },
          fnClick: record => {
            setId(record);
          },
        },
      ],
      pagination,
      ...toRefs(state),
      dateFormat,
      handleClick: item => {
        proxy.$has(`${props.type}:detail`).then(() => goTo(item));
      },

      handleClickByMenu: ({ key }) => {
        if (state.selectedKeys.length) {
          Promise.all(
            state.selectedKeys.map(item =>
              activityApi.update(`${props.type}:group`, {
                ...state.dataSource.find(i => i.id === item),
                campaignGroupId: key,
              })
            )
          ).then(() => {
            proxy.$message.success('操作成功');
            initList(state.current, state.size);
          });
        } else {
          proxy.$message.info('请至少选择一条数据');
        }
      },
      handleChangeByDate: (dates, dateStrings) => {
        state.searchDate = dateStrings[0]
          ? {
              startDate: `${dateStrings[0]} 00:00:00`,
              endDate: `${dateStrings[1]} 23:59:59`,
            }
          : {};
        initList(1, state.size);
      },
      handleChange: selectedKeys => {
        if (selectedKeys.length) {
          if (selectedKeys.length === state.dataSource.length) {
            state.checkAll = true;
            state.indeterminate = false;
          } else {
            state.checkAll = false;
            state.indeterminate = true;
          }
        } else {
          state.checkAll = false;
          state.indeterminate = false;
        }
      },
      handleChangeByCheckAll: ({ target }) => {
        state.indeterminate = false;
        state.selectedKeys = target.checked
          ? state.dataSource.map(item => item.id)
          : [];
      },
      getStatus: item => {
        const now = new Date().getTime();
        const startTime = new Date(item.startTime).getTime();
        const endTime = new Date(item.endTime).getTime();
        if (now < startTime) return 0;
        if (now > endTime) return 1;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.list-wrapper {
  //max-height: calc(100vh - 110px);
  background: #ffffff;
  //box-shadow: 0px 3px 10px 0px @primary-3;
  border-radius: 0 8px 8px 0;

  .list-title {
    padding: 14px 20px;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 0 8px 0 0;
  }
  .move {
    cursor: pointer;
  }
  .banner {
    height: 80px;
    width: 142px;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .title {
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    vertical-align: middle;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: @primary-color;
    }
  }
  .address {
    margin-top: 4px;
    min-height: 22px;
    img {
      width: 16px;
      height: 16px;
      vertical-align: text-bottom;
      margin-right: 4px;
    }
  }
  :deep(.ant-spin-nested-loading) {
    //max-height: ;
  }
  :deep(.ant-list-pagination) {
    margin: 20px 20px 20px 0;
    font-size: 12px;
    .ant-pagination-options-quick-jumper input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #fff;
    }
    a {
      font-size: 14px;
    }
  }
  :deep(.ant-checkbox-group) {
    border-radius: 0 0 8px 0;
  }
}
.statistic-item {
  .item-title {
    margin-top: 5px;
    color: @text-color-secondary;
  }
  .item-count {
    text-align: right;
    font-size: 24px;
    color: @primary-color;
  }
}
:deep(.ant-list-items) {
  //min-height: calc(100vh - 273px);
  height: calc(100vh - 309px);
  overflow-y: auto;
}
.userName {
  padding-left: 32px;
}
.list-time {
  padding-top: 4px;
  img {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
    margin-right: 4px;
  }
}
</style>
