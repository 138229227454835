<template>
  <!--  <a-tabs v-model:activeKey="activeKey">-->
  <!--    <a-tab-pane key="list" tab="列表视图" />-->
  <!--    <a-tab-pane key="calendar" tab="日历视图" />-->
  <!--  </a-tabs>-->
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <a-row type="flex" justify="space-between">
        <a-col>
          <a-button
            type="primary"
            @click="
              $has(`${type}:add`).then(() => $router.push(`${$route.path}/add`))
            "
          >
            <template #icon><plus-outlined /></template>
            创建任务
          </a-button>
        </a-col>
        <a-col flex="1">
          <a-space class="mb-16 fl-right" :gutter="16"
            ><a-select
              v-model:value="taskValue"
              placeholder="选择任务"
              @change="handleSearchTask"
              style="min-width: 164px"
            >
              <a-select-option
                v-for="item in taskList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
            <a-select
              v-model:value="timeStatus"
              placeholder="选择任务状态"
              @change="handleSearchStatus"
              style="min-width: 164px"
            >
              <a-select-option
                v-for="item in genderList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
            <a-input-search
              placeholder="请输入名称或创建人查询"
              allow-clear
              @search="value => handleSearch({ search: value || null })"
          /></a-space>
        </a-col>
      </a-row>
    </div>
  </div>

  <div class="iss-activity">
    <a-row type="flex">
      <a-col flex="240px">
        <iss-sidebar
          v-model:group-list="groupList"
          :type="type"
          @fnSearch="handleSearch"
        />
      </a-col>
      <a-col flex="1" class="list-background">
        <!--        <a-row type="flex" justify="space-between" class="mb-10">-->
        <!--          <a-col :span="5">-->
        <!--            <a-button-->
        <!--              type="primary"-->
        <!--              @click="-->
        <!--                $has(`${type}:add`).then(() =>-->
        <!--                  $router.push(`${$route.path}/add`)-->
        <!--                )-->
        <!--              "-->
        <!--            >-->
        <!--              <template #icon><plus-outlined /></template>-->
        <!--              1111创建任务-->
        <!--            </a-button>-->
        <!--          </a-col>-->
        <!--          <a-col :span="9">-->
        <!--            <a-input-search-->
        <!--              placeholder="请输入名称或创建人查询"-->
        <!--              allow-clear-->
        <!--              @search="value => handleSearch({ search: value || null })"-->
        <!--            />-->
        <!--          </a-col>-->
        <!--        </a-row>-->
        <iss-list
          v-show="activeKey === 'list'"
          :type="type"
          :group-list="groupList"
          :search="search"
        />
        <iss-calendar
          v-show="activeKey === 'calendar'"
          :type="type"
          :search="search"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Col, Row, Select, Space /*Tabs*/ } from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import IssSidebar from './components/sidebar';
import IssList from './components/list';
import IssCalendar from './components/calendar';

export default {
  components: {
    ACol: Col,
    ARow: Row,
    ASpace: Space,
    // ATabs: Tabs,
    // ATabPane: Tabs.TabPane,
    ASelect: Select,
    ASelectOption: Select.Option,
    PlusOutlined,
    IssSidebar,
    IssList,
    IssCalendar,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      type: route.path.split('/')[3],
      search: {},
      groupList: [],
      activeKey: 'list',
      taskValue: null,
      timeStatus: null,
      taskList: [
        { value: '', label: '全部任务' },
        { value: '101', label: '线下主办会' },
        { value: '102', label: '行业参展' },
        { value: '103', label: '线上会议' },
      ],
      genderList: [
        { value: 'null', label: '全部' },
        { value: 'coming', label: '未开始' },
        { value: 'going', label: '进行中' },
        { value: 'finish', label: '已结束' },
      ],
    });
    watch(
      () => route.path,
      path => {
        state.type = path.split('/')[3];
      }
    );

    return {
      ...toRefs(state),
      handleSearchTask: value => {
        let tempIdValue = { tempId: value };
        return Object.assign(state.search, tempIdValue);
      },
      handleSearchStatus: value => {
        let statusValue = { timeStatus: value };
        return Object.assign(state.search, statusValue);
      },
      handleSearch: value => Object.assign(state.search, value),
    };
  },
};
</script>

<style lang="less" scoped>
.iss-activity {
  padding: 0px 16px 16px 16px;
}
.list-background {
  background-color: #fff;
  border-radius: 0 8px 8px 0;
}
</style>
