<template>
  <!--  <div class="sidebar-item">-->
  <!--    <div class="item-title"><span>任务状态</span></div>-->
  <!--    <div class="item-box">-->
  <!--      <ul>-->
  <!--        <li-->
  <!--          :class="{ active: search.timeStatus === null }"-->
  <!--          @click="handleClick({ timeStatus: null })"-->
  <!--        >-->
  <!--          全部-->
  <!--        </li>-->
  <!--        <li-->
  <!--          :class="{ active: search.timeStatus === 'coming' }"-->
  <!--          @click="handleClick({ timeStatus: 'coming' })"-->
  <!--        >-->
  <!--          未开始-->
  <!--        </li>-->
  <!--        <li-->
  <!--          :class="{ active: search.timeStatus === 'going' }"-->
  <!--          @click="handleClick({ timeStatus: 'going' })"-->
  <!--        >-->
  <!--          进行中-->
  <!--        </li>-->
  <!--        <li-->
  <!--          :class="{ active: search.timeStatus === 'finish' }"-->
  <!--          @click="handleClick({ timeStatus: 'finish' })"-->
  <!--        >-->
  <!--          已结束-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="sidebar-item">
    <!--    <div class="item-title">-->
    <!--      <span>任务组别</span>-->
    <!--      -->
    <!--    </div>-->

    <div class="item-box">
      <div class="iss-new" @click="inputVisible = true">
        <a class="iss-a"> <PlusOutlined class="icon" />新建分组</a>
        <!--        <a @click="handleClickSaveItem" v-else-if="itemName" class="iss-a">保存</a>-->
        <!--        <a @click="inputVisible = false" v-else class="iss-a">取消</a>-->
      </div>

      <ul>
        <li
          :class="{ active: search.campaignGroupId === null }"
          @click="handleClick({ campaignGroupId: null })"
        >
          <a-space>
            <img
              src="@/assets/images/groupNameColor.png"
              v-if="search.campaignGroupId === null"
            />
            <img src="@/assets/images/groupNameMin.png" v-else />
            <span>全部</span>
          </a-space>
        </li>
        <li
          v-for="item in groupList"
          :key="item.id"
          :class="{ active: search.campaignGroupId === item.id }"
          @click="handleClick({ campaignGroupId: item.id })"
        >
          <!--        <img src="../../../assets/images/Wechat.png" title="微信号" />   -->

          <a-space>
            <img
              src="@/assets/images/groupNameColor.png"
              v-if="campaignGroupId === item.id"
            />
            <img src="@/assets/images/groupNameMin.png" v-else />
            <span>{{ item.campaignGroupName }}</span>
          </a-space>
          <!--          <a-input-->
          <!--            v-if="item.id === campaignGroupIdEdit && inputHide"-->
          <!--            v-model:value="itemName"-->
          <!--            @blur="handleClickSaveItem"-->
          <!--          >   </a-input>-->

          <!--          <span v-else>{{ item.campaignGroupName }}</span> -->

          <a-dropdown>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1">
                  <a-popconfirm
                    title="确认删除该标签？"
                    @confirm="handleConfirm(item)"
                  >
                    <!--                      <a-tooltip title="删除" placement="right">-->
                    <a class="error-color"
                      ><delete-outlined class="icon" />删除</a
                    >
                    <!--                      </a-tooltip>-->
                  </a-popconfirm>
                </a-menu-item>
                <!--                todo 需要后端提供编辑接口   -->
                <!--                  <a-menu-item key="2" @click="handleClickEdit(item)">-->
                <!--                    <export-outlined style="font-size: 15px" />-->
                <!--                    <a class="error-color"><EditOutlined class="icon" />编辑</a>-->

                <!--                  </a-menu-item>-->
              </a-menu>
            </template>
            <MoreOutlined
              class="iss-icon"
              v-if="item.id === campaignGroupId ? listIcon : ''"
            />
          </a-dropdown>
        </li>
        <a-space v-if="inputVisible" class="pl-4 pr-4 group-input">
          <img src="@/assets/images/groupNameMin.png" />
          <a-input
            v-model:value="itemName"
            placeholder="请输入组别名称"
            v-focus
            @blur="handleClickSaveItem"
          />
        </a-space>
      </ul>

      <!--        <template #prefix><group-outlined /></template>-->
    </div>
  </div>
</template>
<script>
import {
  DeleteOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';
import activityAPI from '@/api/activity.js';
import { Popconfirm, Dropdown, Menu, Space } from 'ant-design-vue';
import { toRaw } from 'vue';

export default {
  components: {
    ASpace: Space,
    APopconfirm: Popconfirm,
    // GroupOutlined,
    DeleteOutlined,
    MoreOutlined,
    PlusOutlined,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    // EditOutlined,
  },
  props: {
    type: String,
    groupList: {
      type: Array,
      default: () => [],
    },
    sidebarVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: {
        timeStatus: null,
        campaignGroupId: null,
      },
      campaignGroupId: '',
      campaignGroupIdEdit: '',
      inputVisible: false,
      inputHide: false,
      listIcon: false,
      itemName: '',
      // 操作栏是否可见
      operationPanelVisible: true,
    };
  },
  created() {
    this.getGroupList();
  },
  methods: {
    getGroupList() {
      activityAPI.getGroupList(`${this.type}:group`).then(({ records }) => {
        this.$emit('update:groupList', records);
      });
    },
    handleClickSaveItem() {
      if (this.itemName) {
        activityAPI
          .addGroup(`${this.type}:group`, {
            campaignGroupName: this.itemName,
            label: this.itemName,
          })
          .then(() => {
            this.itemName = '';
            this.$message.success('操作成功');
            this.inputHide = false;
            this.getGroupList();
          });
      }
      this.inputVisible = false;
    },
    handleConfirm({ id }) {
      activityAPI.deleteGroup(`${this.type}:group`, { ids: [id] }).then(() => {
        this.$message.success('操作成功');
        this.getGroupList();
      });
    },
    // handleClickEdit(item) {
    //   console.log('item', item)
    //   this.campaignGroupIdEdit = item.id;
    //   this.itemName = item.campaignGroupName;
    //   this.inputHide = true;
    //
    // },
    handleClick(value) {
      this.campaignGroupId = value.campaignGroupId;
      this.listIcon = true;
      this.$emit('fnSearch', toRaw(Object.assign(this.search, value)));
    },
  },
};
</script>

<style lang="less" scoped>
.sidebar-item {
  height: 100%;
  //margin: 10px 20px 40px 0;
  .item-title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 8px;
    span {
      font-size: 16px;
    }
  }
  .item-box {
    padding: 6px 20px;
    background: #fff;
    height: 100%;
    border-radius: 8px 0 0 8px;
    //box-shadow: 0px 3px 10px 0px @primary-3;
    //min-height: 653px;
    //max-height: 653px;
    overflow: auto;
    border-right: 1px solid #f0f0f0; //分割线
    ul {
      margin-top: 1em;
      max-height: calc(100vh - 260px);
      li {
        display: flex;
        justify-content: space-between;
        height: 28px;
        line-height: 28px;
        padding: 0 4px;
        margin-bottom: 5px;
        //border-radius: 15px;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          padding-left: 16px;
          background-color: @primary-2;
        }
        &.active {
          padding-left: 16px;
          color: @primary-color;
          background-color: @primary-2;
          border-radius: 4px;
        }
      }
      .group-input {
        background-color: @primary-2;
        border-radius: 4px;
        height: 28px;
        :deep(.ant-input) {
          height: 22px;
          padding: 0 4px;
        }
      }
    }
  }
}
.iss-icon {
  width: 16px;
  height: 16px;
  margin-top: 7px;
  color: @text-color;
}
.iss-a {
  font-weight: 400;
  color: #1d2129;
  line-height: 22px;
}
.iss-new {
  text-align: center;
  border: 1px dashed #e5e6eb;
  margin-top: 10px;
  padding: 5px 0px 5px 0px !important;
  border-radius: 4px;
}
//.li-all {
//  background: #F0F5FF;
//  border-radius: 4px;
//}
.error-color {
  color: #1d2129;
  font-size: 14px;
  font-weight: 400;
}
.icon {
  margin-right: 4px;
}
</style>
