'use strict';

import http from '@/utils/http';
import store from '@/store';

export default {
  getList(code, params) {
    return http.get('/issmart-campaign/campaign/page', { params, code });
  },
  getItemById(code, id) {
    return http.get(`/issmart-campaign/campaign/ext/${id}`, { code });
  },
  add(code, data) {
    return http.post('/issmart-campaign/campaign/ext/add', data, {
      code,
      headers: {
        CampaignOrgId: store.state.common.CampaignOrgId,
        CreateUser: store.state.common.CreateUser,
      },
    });
  },
  update(code, data) {
    return http.put('/issmart-campaign/campaign', data, {
      code,
      headers: {
        CampaignOrgId: store.state.common.CampaignOrgId,
        CreateUser: store.state.common.CreateUser,
      },
    });
  },
  updateApp(code, data) {
    return http.post('/issmart-campaign/campaign/ext/saveApps', data, {
      code,
      headers: {
        CampaignOrgId: store.state.common.CampaignOrgId,
        CreateUser: store.state.common.CreateUser,
      },
    });
  },
  delete(code, params) {
    return http.delete('/issmart-campaign/campaign', {
      params,
      code,
      headers: {
        CampaignOrgId: store.state.common.CampaignOrgId,
        CreateUser: store.state.common.CreateUser,
      },
    });
  },
  getGroupList(code) {
    return http.get('/issmart-campaign/campaignGroup/page', { code });
  },
  addGroup(code, data) {
    return http.post('/issmart-campaign/campaignGroup', data, { code });
  },
  deleteGroup(code, params) {
    return http.delete('/issmart-campaign/campaignGroup', { params, code });
  },
  getTemplateList(code) {
    return http.get('/issmart-campaign/campaignTemplate/list', {
      code,
    });
  },
  getTacticList(code, params) {
    return http.get('/issmart-campaign/campaignTactic/tacticList', {
      params,
      code,
    });
  },
  getAppList(code, params) {
    return http.get('/issmart-campaign/campaign/ext/tacticAppList', {
      params,
      code,
    });
  },
  getCampaignById(code, params) {
    return http.get(`/issmart-campaign/campaign/getCampaignById`, {
      params,
      code,
    });
  },
  authorityOrgByUserId(code, params) {
    return http.get(`/authority/dataScope/authorityOrgByUserId`, {
      params,
      code,
    });
  },
};
